import { useState } from "react";
import { forgotAcc, resetAcc, setPassAcc } from '../Disp/endpoint';

function ResetForm(props) {
    const [confirm, setConfirm] = useState(0);
    const [error, setError] = useState("");
    const [code, setCode] = useState("");
    const [email, setEmail] = useState("");
    const [resetCode, setResetCode] = useState("");
    const [password, setPassword] = useState("");
    const [repassword, setRepass] = useState("");

    function backToSign() {
        props.goTo(2);
    }

    function getCode() {
        if (!email) {
            setError('Write email');
            return;
        }
        forgotAcc(email).then(value => {
            switch (value) {
                case "ok":
                    setError('');
                    setConfirm(1);
                    break;
                case "error":
                    setError('Invalid email');
                    break;
            }
        })
    }

    function getReset() {
        if (!code) {
            setError('Invalid code');
            return;
        }
        resetAcc(email, code).then(value => {
            switch (value.status) {
                case "ok":
                    setError('');
                    setResetCode(value.reset);
                    setConfirm(2);
                    break;
                case "error":
                    setError('Code error');
                    break;
            }
        });
    }

    function resetPassword() {
        if (!password) {
            setError('Invalid string passoword.');
            return;
        }
        if (password.length < 6) {
            setError('Password is short.');
            return;
        }
        setPassAcc(email, password, resetCode).then(value => {
            switch (value) {
                case "ok":
                    backToSign();
                    break;
                case "error":
                    setError('Server error');
                    break;
            }
        });
    }

    return (
        [confirm == 0 && <div className="reset">
            <div className="form">
                <h2>RESET<br />PASSWORD</h2>
                <div className="input">
                    <p className="error">{error}</p>
                    <label>E-mail</label>
                    <input type="text" onChange={ (e) => setEmail(e.target.value) }/>
                    <span>Just enter the email you used to sign up and we'll send you a link to reset it</span>
                </div>
                <div className="buttons-group">
                    <button onClick={backToSign}>Cancel</button>
                    <button onClick={getCode}>Reset</button>
                </div>
            </div>
        </div>,
        confirm == 1 && <div className="confirm">
            <div className="form">
                <h2>RESET<br />PASSWORD</h2>
                <p className="error">{error}</p>
                <div className="area">
                    <span>We have sent you a reset code to your email.<br />Please, check your inbox and enter the code.</span>
                </div>
                <div className="input">
                    <label>Enter the code:</label>
                    <input type="text" style={{ "text-align": "center" }} onChange={(e) => setCode(e.target.value) } />
                </div>
                <div className="buttons-group">
                    <button onClick={backToSign}>Cancel</button>
                    <button onClick={getReset}>Reset</button>
                </div>
            </div>
        </div>,
        confirm == 2 && <div className="confirm">
            <div className="form">
                <h2>NEW<br />PASSWORD</h2>
                <p className="error">{error}</p>
                <div className="area">
                    <span>We have sent you a reset code to your email.<br />Please, check your inbox and enter the code.</span>
                </div>
                <div className="input">
                    <label>New password</label>
                    <input type="text" onChange={(e) => setPassword(e.target.value) }/>
                    <span>Password must be at least 8 characters and contain numbers, letters and special characters.</span>
                </div>
                <div className="input">
                    <label>Repeat password</label>
                    <input type="text" onChange={(e) => setRepass(e.target.value) }/>
                </div>
                <div className="buttons-group">
                    <button onClick={backToSign}>Cancel</button>
                    <button onClick={resetPassword}>Save</button>
                </div>
            </div>
        </div>]
    )
}

export default ResetForm;