import { useEffect, useState } from "react";
import { profile } from "../../Disp/endpoint";
import { getToken } from "../../Disp/token";


function User(props) {
    const [_profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true);

    let photo_url;

    const getData = async () => {
        let token = getToken();
        let data = await profile(token);
        setLoading(false);
        return data;
    }

    useEffect(async () => {
        (async () => {
            let data = await getData();
            setProfile(data);
        })();
    }, []);

    if (_profile && _profile.photoId) {
        photo_url = `https://create.infty.art/files/${_profile.photoId}`
    } else {
        photo_url = 'https://create.infty.art/images/png/blank.png'
    }

    return [
        <div className="exit-block"><button className="exit" onClick={() => props.logout()}></button></div>,
        <div className="absolute" style={{'top': '160px','bottom': '0px'}}>
        <div className="profile-form">
            <div className="photo" style={{ 'background-image': `url(${photo_url})` }}></div>
            {_profile.username && <div className="profile-name">{_profile.username}</div>}
            {_profile.about && <div className="profile-desc">{_profile.about}</div>}
            <div className="profile-buttons">
                <div className="profile-button" onClick={() => props.setPage(7) }>
                    <span className="name">Artworks</span>
                    <div className="icon"></div>
                </div>
                <div className="profile-button" onClick={() => props.setPage(6) }>
                    <span className="name">Materials</span>
                    <div className="icon"></div>
                </div>
                <div className="profile-button"  onClick={() => props.setPage(8) }>
                    <span className="name">Settings</span>
                    <div className="icon"></div>
                </div>
            </div>
            <div className="add-line">
                <button onClick={() =>  props.setPage(4)}></button>
            </div>
            </div>
        </div>
    ]
}

export default User;