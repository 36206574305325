import { useState } from "react";
import Home from "./Main/Home";
import Notify from "./Main/Notify";
import User from "./Main/User";
import Add from "./Main/Add";
import { check, setNewPassAcc, uploadProject, uploadUserData } from "../Disp/endpoint";
import { getToken, resetToken } from "../Disp/token";
import Details from "./Main/Details";
import Materials from "./Main/Materials";
import Artworks from "./Main/Artworks";
import Settings from "./Main/Settings";
import Change from "./Main/Change";
import Edit from "./Main/Edit";

function MainForm(props) {
    const [page, setPage] = useState(0);
    const [profile, setProfile] = useState(0);

    const [backpage, setBackpage] = useState();
    const [detail, setDetail] = useState({});
    const [images, upImages] = useState([]);
    const [project, upProject] = useState("");
    const [about, upAbout] = useState("");
    const [name, upName] = useState("");
    const [upError, setUpError] = useState("");
    const [password, setPassword] = useState({});
    const [className, setClassName] = useState("window");
    const [userData, setUserData] = useState({});

    function checkProfile(value) {
        if (profile != value) {
            setProfile(value);
        }
    }

    function setPair(key, value) {
        console.log(password);
        let _password = password;
        _password[key] = value;
        setPassword(_password);
    }

    async function sendData(data) {
        let token = await getToken();
        let result = await uploadProject(token, data);
        return result;
    }

    function setImages(value) {
        let imgs = [];
        console.log(value);
        for (let img of value) {
            imgs.push({
                data_url: img.data_url,
                name: img.name,
                type: img.type,
                size: img.size
            })
        }
        upImages(imgs);
    }

    function setProject(value) {
        upProject(value);
    }

    function setEditUser(key,value) {
        let _userData = userData;
        _userData[key] = value;
        setUserData(_userData);
    }

    function setAbout(value) {
        upAbout(value);
    }

    function setName(value) {
        upName(value);
    }

    function logout() {
        resetToken();
        props.goTo(0);
    }

    async function saveProject() {
        setUpError("");
        if (images.length == 0 || !project || !about || !name) {
            setUpError('Exist not filled fields');
            return;
        }
        let result = await sendData({
            name: name,
            project: project,
            about: about,
            images: images
        });
        if (result) {
            backButton();
        } else {
            setUpError('Server error');
        }
    }

    function backButton() {
        setUpError("")
        setPage(0);
    }

    function showDetails(o, back = 0) {
        setDetail(o);
        setBackpage(back);
        setPage(5);
    }

    function checkClassName(value) {
        if (className != value) {
            setClassName(value);
        }
    }

    async function changePassword() {
        setUpError("");
        let token = getToken();
        let result = await setNewPassAcc(token, password.old, password.new);
        if (result != 'ok') {
            switch (result) {
                case "passerror":
                    setUpError("Current password error.");
                    break;
                default:
                    setUpError("Server error.");
                    break;
            }
        } else {
            setPage(8);
        }
    }

    async function updateUserData() {
        let token = getToken();
        console.log(userData);
        uploadUserData(token, userData).then(status=> {
            setPage(8);
            setUserData({});
        });
    }

    let render;
    switch (page) {
        case 0:
            checkProfile(0);
            checkClassName("window");
            render = <Home showDetails={showDetails} setPage={setPage} />;
            break;
        case 2:
            checkProfile(0);
            checkClassName("window");
            render = <Notify showDetails={showDetails} />;
            break;
        case 3:
            checkProfile(0);
            checkClassName("user-window");
            render = <User setPage={setPage} logout={logout}/>;
            break;
        case 4:
            checkProfile(1);
            checkClassName("window");
            render = <Add setImages={setImages} setProject={setProject} setAbout={setAbout} setName={setName} upError={upError} />
            break;
        case 5:
            checkProfile(0);
            checkClassName("details-window");
            render = <Details setPage={setPage} detail={detail} backpage={backpage} />;
            break;
        case 6:
            checkProfile(0);
            checkClassName("user-window");
            render = <Materials setPage={setPage} />;
            break;
        case 7:
            checkProfile(0);
            checkClassName("user-window");
            render = <Artworks setPage={setPage} showDetails={showDetails} />;
            break;
        case 8:
            checkProfile(0);
            checkClassName("user-window");
            render = <Settings setPage={setPage} />;
            break;
        case 9:
            checkProfile(2);
            checkClassName("user-window");
            render = <Change setPage={setPage} setPair={setPair} upError={upError} />;
            break;
        case 10:
            checkProfile(3);
            checkClassName("user-window");
            render = <Edit setPage={setPage} setEditUser={setEditUser} />;
            break;
    }
    return (
        <div className="main">
            <div key={"window"} className={className}>
                {render}
            </div>
            <div key={"footer"} className="footer">
                <div className="submenu">
                    {profile == 0 &&
                        [<button key={1} onClick={() => setPage(0)} className={"home " + (page == 0 ? 'selected' : '')}></button>,
                        <button key={2} onClick={() => window.open('https://t.me/rvspace_rikibot', '_blank')} className={"chat " + (page == 1 ? 'selected' : '')}></button>,
                        <button key={3} onClick={() => setPage(2)} className={"notif " + (page == 2 ? 'selected' : '')}></button>,
                        <button key={4} onClick={() => setPage(3)} className={"profile " + (page == 3 ? 'selected' : '') + (page == 6 ? 'selected' : '') + (page == 7 ? 'selected' : '') + (page == 8 ? 'selected' : '') + (page == 9 ? 'selected' : '') + (page == 10 ? 'selected' : '')}></button>]
                    }{profile == 1 &&
                        [<button key={5} className="sub-button" onClick={backButton}>Back</button>,
                        <button key={6} className="sub-button" onClick={saveProject}>Save</button>]
                    }{profile == 2 &&
                        [<button key={5} className="sub-button" onClick={() => setPage(8)}>Back</button>,
                        <button key={6} className="sub-button" onClick={() => changePassword()}>Save</button>]
                    }
                    {profile == 3 &&
                        [<button key={5} className="sub-button" onClick={() => setPage(8)}>Back</button>,
                        <button key={6} className="sub-button" onClick={() => updateUserData()}>Save</button>]
                    }
                </div>
            </div>
        </div>
    )
}

export default MainForm;