function Change(props) {
    return [
        <h2>CHANGE<br />PASSWORD</h2>,
        <div className="profile-form">
            <div className="form">
                <div className="error-block">
                    <p className="error">{props.upError}</p>
                </div>
                <div className="input">
                    <label>Current password</label>
                    <input type="text" onChange={(e) => props.setPair('old', e.target.value)} />
                    <span>Password must be at least 8 characters and contain numbers, letters and special characters.</span>
                </div>
                <div className="input">
                    <label>New password</label>
                    <input type="text" onChange={(e) => props.setPair('new', e.target.value)} />
                    <span>Password must be at least 8 characters and contain numbers, letters and special characters.</span>
                </div>
            </div>
        </div>
    ]
}

export default Change;