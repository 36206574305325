function lang_msg(code) {
    let msg = {
        'new': 'Your works are marked as new.',
        'payed': 'Your works are sold.',
        'inprogress': 'Your work is under consideration.',
        'incollection': 'Your work has been added to the collection.',
        'cancelled': 'Your work has been rejected.',
        'closed': 'Your works are closed.'
    }
    return msg[code];
}

function stat_msg(code) {
    let msg = {
        'new': 'NEW',
        'payed': 'PAYED',
        'inprogress': 'IN PROGRESS',
        'incollection': 'IN COLLECTION',
        'cancelled': 'CANCELLED',
        'closed': 'CLOSED'
    }
    return msg[code];
}

export { lang_msg, stat_msg }