import { check } from "../Disp/endpoint";
import { getToken } from "../Disp/token";

function PrimeForm(props) {
    function goToReg() {
        props.goTo(1);
    }

    let token = getToken();
    if (token && token != null) {
        check(token).then(status => {
            if (status == 'ok') {
                props.goTo(4);
            }
        });
    }

    function goToSign() {
        props.goTo(2);
    }

    return (
        <div className="prime">
            <div className="form">
                <div className="logo">
                    <img className="vector" src="images/svg/Vector.svg" />
                    <img className="group" src="images/svg/Group.svg" />
                </div>
                <button className="primary" onClick={goToReg}>Register</button>
            </div>
            <div className="footer block-login">
                <p>Already have an account?</p>
                <button className="clear" onClick={goToSign}>Log in</button>
            </div>
        </div>
    )
}

export default PrimeForm;