import { useState } from "react";
import { login } from "../Disp/endpoint";

function SignForm(props) {
    const [username, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    const [error, setError] = useState('');

    function goToReg() {
        props.goTo(1);
    }

    function goToRestore() {
        props.goTo(3);
    }

    function Sign() {
        login(username, password).then((value) => {
            if (value != null) {
                if (remember === true) {
                    localStorage.setItem('token', value);
                } else {
                    sessionStorage.setItem('token', value);
                }
                props.goTo(4);
            } else {
                setError('Wrong authorization.');
            }
        })
    }

    function onRemember(e) {
        if (e.target.value == 'on') {
            setRemember(true);
        } else {
            setRemember(false);
        }
    }

    return (
        <div className="sign">
            <div className="form">
                <h2>SIGN IN</h2>
                <p className="error">{error}</p>
                <div className="input">
                    <label>E-mail</label>
                    <input type="text" onChange={(e) => setLogin(e.target.value)} />
                </div>
                <div className="input" style={{'padding-bottom': '35px'}}>
                    <label>Password</label>
                    <input type="password" onChange={(e) => setPassword(e.target.value)} />
                    <button className="clear frg-link" onClick={goToRestore}>Forgot password?</button>
                </div>
                <div className="input">
                    <label htmlFor="remember"><input className="checkbox" type="checkbox" id="remember" name="remember" onChange={(e) => onRemember(e)} />Remember me</label>
                </div>
                <div className="input">
                    <button className="primary" onClick={Sign}>Sign in</button>
                </div>
                <div className="input">
                    <p className="terms">By clicking on the button, you agree <br /> to the <a>Terms of Service</a> and <a>Privacy Policy</a></p>
                </div>
            </div>
            <div className="block-login">
                <p>Don't have an account?</p>
                <button className="clear" onClick={goToReg}>Sign up</button>
            </div>
        </div>
    )
}

export default SignForm;