import { useEffect, useState } from "react";
import { getToken } from "../../Disp/token";
import { unread } from "../../Disp/endpoint";
import HorizontalScroll from 'react-horizontal-scrolling';
import { lang_msg } from "../../Disp/lang";
import { timeSince } from "../../Disp/ago";
import { CircularProgress } from "@material-ui/core";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import Horizontal from "../../Disp/Horizontal";

function Notify(props) {
    const [unreaded, setUnread] = useState([]);
    const [loading, setLoading] = useState(true);

    const getData = async () => {
        let token = getToken();
        let data = await unread(token);
        setLoading(false);
        return data;
    }

    useEffect(async () => {
        (async () => {
            let data = await getData();
            setUnread(data);
        })();
    }, []);


    const showDetails = async (id) => {
        let obj = unreaded.find((value) => {
            return value.data._id == id;
        });
        if (obj) {
            let detail = obj.data;
            detail.imgs = obj.imgs;
            props.showDetails(detail, 2);
        } else {
            console.error('Project not found');
        }
    }

    return [
        <h3>NOTIFICATIONS</h3>,
        loading && <div className="loading-bar"><CircularProgress color="secondary" /></div>,
        unreaded.map((item, index, arr) => {
            return [
                <div className="notify">
                    <div className="element" onClick={() => showDetails(item.data._id)}>
                        <p>{lang_msg(item.status.status)}</p>
                        <div className="imgs">
                            <BrowserView>
                                <HorizontalScroll>
                                    {
                                        item.imgs.map(img => {
                                            if (img.mime && img.mime.indexOf('image') == -1) {
                                                return <div className="no-type" onClick={() => window.open(`https://create.infty.art/image/${img.file_unique_id}`, '_blank')} >
                                                    <img key={img._id} src="https://create.infty.art/images/png/item.png"></img>
                                                    <p>{img.file_name}</p>
                                                </div>
                                            } else {
                                                return <div key={img._id} className="img" onClick={() => window.open(`https://create.infty.art/image/${img.file_unique_id}`, '_blank')} style={{ 'background-image': `url(https://create.infty.art/image/${img.file_unique_id})` }}></div>
                                            }
                                        })
                                    }
                                </HorizontalScroll>
                            </BrowserView>
                            <MobileView>
                                <Horizontal>
                                    {
                                        item.imgs.map(img => {
                                            if (img.mime && img.mime.indexOf('image') == -1) {
                                                return <div className="no-type" onClick={() => window.open(`https://create.infty.art/image/${img.file_unique_id}`, '_blank')} >
                                                    <img key={img._id} src="https://create.infty.art/images/png/item.png"></img>
                                                    <p>{img.file_name}</p>
                                                </div>
                                            } else {
                                                return <div key={img._id} className="img" onClick={() => window.open(`https://create.infty.art/image/${img.file_unique_id}`, '_blank')} style={{ 'background-image': `url(https://create.infty.art/image/${img.file_unique_id})` }}></div>
                                            }
                                        })
                                    }
                                </Horizontal>
                            </MobileView>
                        </div>
                        <p>{timeSince(new Date(item.status.date))} ago</p>
                    </div></div>,
                (arr.length > 1) && (index != arr.length - 1) && <hr className="border-botom"></hr>
            ]
        })
    ]
}

export default Notify;