import { useEffect, useState } from "react";
import { profile } from "../../Disp/endpoint";
import { getToken } from "../../Disp/token";

function Edit(props) {
    const [_profile, setProfile] = useState({});
    const [photo, setPhotoData] = useState(null);

    let photo_url;

    const getData = async () => {
        let token = getToken();
        let data = await profile(token);
        //setLoading(false);
        return data;
    }

    function handleChange(event) {
        let file = event.target.files[0];
        let _file = {
            data_url: '',
            name: file.name,
            type: file.type,
            size: file.size
        }
        const reader = new FileReader();
        reader.addEventListener("load", () => {
            _file.data_url = reader.result.split(',')[1];
            props.setEditUser('img', _file);
            setPhotoData(reader.result);
        }, false);
        if (file) {
            reader.readAsDataURL(file);
        }
    }

    useEffect(async () => {
        (async () => {
            let data = await getData();
            setProfile(data);
        })();
    }, []);

    if (_profile && _profile.photoId) {
        photo_url = `https://create.infty.art/files/${_profile.photoId}`
    } else {
        photo_url = 'https://create.infty.art/images/png/blank.png';
    }

    props.setEditUser('username', _profile.username);
    props.setEditUser('birth', _profile.date);
    props.setEditUser('location', _profile.location);
    props.setEditUser('about', _profile.about);

    return [
        <h2>EDIT PROFILE</h2>,
        <div className="photo" style={{'background-image': (photo == null)?`url(${photo_url})`:`url(${photo})`}}>
            <input type="file" id="load_img" style={{"display":"none"}} onChange={handleChange}/>
            <div className="add-photo"><button onClick={() => document.getElementById('load_img').click()}></button></div>
        </div>,
        <div className="absolute">
            <div className="profile-form">
                <div className="form">
                    <div className="input">
                        <label>Name</label>
                        <input type="text" defaultValue={_profile.username} onChange={(e) => props.setEditUser('username', e.target.value)}/>
                    </div>
                    <div className="input">
                        <label>Birthday date</label>
                        <input type="date" defaultValue={_profile.date} onChange={(e) => props.setEditUser('birth', e.target.value)}/>
                    </div>
                    <div className="input">
                        <label>Location</label>
                        <input type="text" defaultValue={_profile.location} onChange={(e) => props.setEditUser('location', e.target.value)}/>
                    </div>
                    <div className="input">
                        <label>About me</label>
                        <textarea defaultValue={_profile.about} onChange={(e) => props.setEditUser('about', e.target.value)}/>
                    </div>
                </div>
            </div>
        </div>
    ]
}

export default Edit;