import { MenuItem, Select } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { listProjects } from "../../Disp/endpoint";
import { useDropzone } from "react-dropzone";

function Add(props) {
    const [_projects, setProject] = useState([]);
    const [files, setFiles] = useState([]);

    function readFile(file) {
        return new Promise((res, rej) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                res(reader.result);
            }, false);
            reader.readAsDataURL(file);
        });
    }

    const onDrop = useCallback(async (acceptedFiles) => {
        let _files = [];
        for (let _file of acceptedFiles) {
            _files.push({
                data_url: await readFile(_file),
                name: _file.name,
                type: _file.type || 'application/octet-stream',
                size: _file.size
            });
        }
        setFiles(_files);
        props.setImages(_files);
      }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
    
    const getData = async() => {
        let data = await listProjects();
        return data;
    }

    const onSelectChange = (e) => {
        props.setProject(e.target.value)
    }

    const onTextChange = (e) => {
        props.setAbout(e.target.value)
    }

    const onInputChange = (e) => {
        props.setName(e.target.value)
    }
    
    const dropImages = () => {
        setFiles([]);
    }

    useEffect(async() => {
        (async () => {
            let data = await getData();
            setProject(data);
        })();
    }, []);

    console.log(files);

    return ([
        <h2 style={{ "text-align": "center" }}>ADD NEW</h2>,
        <p className="error">{props.upError}</p>,
        <div className="project" >
            <div {...getRootProps()}>
            <input {...getInputProps()} />
            {[files.length == 0 && <div className="loadbox">
                        <div className="load">
                            <button ></button>
                        </div>
                    </div>,
                    files.length > 0 && [<div className="images-box">
                    {
                    files.map((file, index, arr) => {
                        if (file?.type && file.type.indexOf('image') != -1) {
                            return <img key={index} src={file.data_url}></img>
                        } else {
                            return <div className="no-type">
                                <img key={index} src="https://create.infty.art/images/png/item.png"></img>
                                <p>{file.name}</p>
                                </div>
                        }
                    })}
                </div>
            ]]}
            </div>
            {files.length > 0 && <div className="buttons-box">
                    <button onClick={dropImages}>Reset</button>
            </div>}
            <div className="input">
                <label>Name</label>
                <input type="text" onChange={onInputChange}/>
            </div>
            <div className="input">
                <label>Project</label>
                <Select className="select" onChange={onSelectChange}>
                    {
                        _projects.map((item, index, arr) => {
                            return <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                        })
                    }
                </Select>
            </div>
            <div className="input">
                <label>About</label>
                <textarea onChange={onTextChange}/>
                <span>Maximum is 250 charactes</span>
            </div>
        </div>
    ])
}

export default Add;