import { useState } from "react";
import HorizontalScroll from 'react-horizontal-scrolling';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import Horizontal from "../../Disp/Horizontal";

function Details(props) {
    const [item, setItem] = useState(props.detail);

    function back() {
        props.setPage(props.backpage);
    }

    return (<div key={item._id} className="detail">
        <div className="imgs">
            <BrowserView>
                <HorizontalScroll>
                    {
                        item.imgs.map(img => {
                            if (img.mime && img.mime.indexOf('image') == -1) {
                                return <div className="no-type" onClick={() => window.open(`https://create.infty.art/image/${img.file_unique_id}`, '_blank')} >
                                    <img key={img._id} src="https://create.infty.art/images/png/item.png"></img>
                                    <p>{img.file_name}</p>
                                </div>
                            } else {
                                return <div key={img._id} className="img" onClick={() => window.open(`https://create.infty.art/image/${img.file_unique_id}`, '_blank')} style={{ 'background-image': `url(https://create.infty.art/image/${img.file_unique_id})` }}></div>
                            }
                        })
                    }
                </HorizontalScroll>
            </BrowserView>
            <MobileView>
                <Horizontal>
                    {
                        item.imgs.map(img => {
                            if (img.mime && img.mime.indexOf('image') == -1) {
                                return <div className="no-type" onClick={() => window.open(`https://create.infty.art/image/${img.file_unique_id}`, '_blank')} >
                                    <img key={img._id} src="https://create.infty.art/images/png/item.png"></img>
                                    <p>{img.file_name}</p>
                                </div>
                            } else {
                                return <div key={img._id} className="img" onClick={() => window.open(`https://create.infty.art/image/${img.file_unique_id}`, '_blank')} style={{ 'background-image': `url(https://create.infty.art/image/${img.file_unique_id})` }}></div>
                            }
                        })
                    }
                </Horizontal>
            </MobileView>
        </div>
        <div className="detail-bar">
            <div className="title-bar">
                <div className="name-bar">
                    <h2>{item.name}</h2>
                    <p>{(item.imgs.length > 1) ? `${item.imgs.length} items` : `${item.imgs.length} item`}</p>
                </div>
                {
                    item.cost && <div className="cost">
                        {item.cost}<br />
                        {item.currency}
                    </div>
                }
            </div>
            <div className="info-bar">
                <h2>INFO</h2>
                <p className="info-text">
                    {item.desc}
                </p>
                <div className="buttons-group">
                    <button onClick={back}>Back</button>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Details;