const url = 'https://create.infty.art';

async function login(username, password){
    let response = await fetch( url + '/auth/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            login: username,
            password: password
        })
    }).then(res => res.json());
    if (response.result == true) {
        return response.token;
    }
    return null;
}

async function check(token) {
    let response = await fetch( url + '/auth/check', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        }
    }).then(res => res.json());
    return response.status;
}

async function register(email, username, date, pass){
    let response = await fetch( url + '/auth/reg', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
            username: username,
            date: date,
            pass: pass
        })
    }).then(res => res.json());
    return response.status;
}

async function confirmAcc(email, code){
    let response = await fetch( url + '/auth/confirm', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
            code: code
        })
    }).then(res => res.json());
    return response.status;
}

async function forgotAcc(email){
    let response = await fetch( url + '/auth/forgot', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email
        })
    }).then(res => res.json());
    return response.status;
}

async function setPassAcc(email,pass,reset){
    let response = await fetch( url + '/auth/setpassword', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
            pass: pass,
            reset: reset
        })
    }).then(res => res.json());
    return response.status;
}

async function setNewPassAcc(token, old, _new){
    let response = await fetch( url + '/auth/changepass', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        },
        body: JSON.stringify({
            old: old,
            new: _new
        })
    }).then(res => res.json());
    return response.status;
}

async function resetAcc(email, code){
    let response = await fetch( url + '/auth/reset', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            email: email,
            code: code
        })
    }).then(res => res.json());
    return response;
}

async function projects(token) {
    let response = await fetch( url + '/projects/orders', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        }
    }).then(res => res.json());
    if (response.result == true) {
        return response.projects;
    }
    return [];
}

async function uploadUserData(token, data) {
    let response = await fetch( url + '/profile/update', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        },
        body: JSON.stringify(data)
    }).then(res => res.json());
    return response.status;
}

async function unread(token) {
    let response = await fetch( url + '/projects/unreads', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        }
    }).then(res => res.json());
    if (response.result == true) {
        return response.projects;
    }
    return [];
}

async function profile(token) {
    let response = await fetch( url + '/profile/get', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        }
    }).then(res => res.json());
    if (response.result == true) {
        return response.user;
    }
    return {};
}


async function read(token) {
    let response = await fetch( url + '/projects/read', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        }
    });
}

async function listProjects(token) {
    let response = await fetch( url + '/projects/list', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    }).then(res => res.json());
    if (response.result == true) {
        return response.list;
    }
    return [];
}

async function uploadProject(token, data) {
    let response = await fetch( url + '/projects/upload', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-access-token': token
        },
        body: JSON.stringify(data)
    }).then(res => res.json(), (err) => {
        console.log(err);
    });
    if (response && response.result == true) {
        return true;
    }
    return false;
}

export { login, check, register, uploadUserData, confirmAcc, setPassAcc, setNewPassAcc, resetAcc, forgotAcc, projects, listProjects, uploadProject, unread, read, profile };