import { useEffect, useState } from "react";
import { getToken } from "../../Disp/token";
import { projects } from "../../Disp/endpoint";
import HorizontalScroll from 'react-horizontal-scrolling';
import { CircularProgress } from "@material-ui/core";
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import Horizontal from "../../Disp/Horizontal";

function Home(props) {
    const [_projects, setProject] = useState([]);
    const [loading, setLoading] = useState(true);

    const getData = async () => {
        let token = getToken();
        let data = await projects(token);
        setLoading(false);
        return data;
    }

    useEffect(async () => {
        (async () => {
            let data = await getData();
            setProject(data);
        })();
    }, []);

    const showDetails = async (id) => {
        let obj = _projects.find((value) => {
            return value._id == id;
        });
        if (obj) {
            props.showDetails(obj, 0);
        } else {
            console.error('Project not found');
        }
    }

    return ([
        <h2>PROJECTS</h2>,
        loading && <div className="loading-bar"><CircularProgress color="secondary" /></div>,
        <div className="projects">
            {
                _projects.map(item => {
                    return <div key={item._id} className="element" onClick={() => showDetails(item._id)}>
                        <div className="header"><h4>{item.name}</h4><button><img src="images/png/arrow-forward.png"></img></button></div>
                        <div className="imgs">
                            <BrowserView>
                                <HorizontalScroll>
                                    {
                                        item.imgs.map(img => {
                                            if (img.mime && img.mime.indexOf('image') == -1) {
                                                return <div className="no-type" onClick={() => window.open(`https://create.infty.art/image/${img.file_unique_id}`, '_blank')} >
                                                    <img key={img._id} src="https://create.infty.art/images/png/item.png"></img>
                                                    <p>{img.file_name}</p>
                                                </div>
                                            } else {
                                                return <div key={img._id} className="img" onClick={() => window.open(`https://create.infty.art/image/${img.file_unique_id}`, '_blank')} style={{ 'background-image': `url(https://create.infty.art/image/${img.file_unique_id})` }}></div>
                                            }
                                        })
                                    }
                                </HorizontalScroll>
                            </BrowserView>
                            <MobileView>
                                <Horizontal>
                                    {
                                        item.imgs.map(img => {
                                            if (img.mime && img.mime.indexOf('image') == -1) {
                                                return <div className="no-type" onClick={() => window.open(`https://create.infty.art/image/${img.file_unique_id}`, '_blank')} >
                                                    <img key={img._id} src="https://create.infty.art/images/png/item.png"></img>
                                                    <p>{img.file_name}</p>
                                                </div>
                                            } else {
                                                return <div key={img._id} className="img" onClick={() => window.open(`https://create.infty.art/image/${img.file_unique_id}`, '_blank')} style={{ 'background-image': `url(https://create.infty.art/image/${img.file_unique_id})` }}></div>
                                            }
                                        })
                                    }
                                </Horizontal>
                            </MobileView>
                        </div>
                    </div>
                })
            }
        </div>,
        !loading && <div className="add-line">
            <button onClick={() => props.setPage(4)}></button>
        </div>
    ])
}

export default Home;