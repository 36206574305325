function Materials(props) {
    return [
        <div className="back-block"><button className="back-block" onClick={() =>  props.setPage(3)}></button></div>,
        <h2>MATERIALS</h2>,
        <p className="desc">All the necessary working materials to participate in projects</p>,
        <div className="profile-form">
            <div className="profile-buttons">
                <div className="profile-button" onClick={() => window.open('https://google.com','_blank')}>
                    <span className="name">Guides</span>
                    <div className="icon"></div>
                </div>
                <div className="profile-button" onClick={() => window.open('https://google.com','_blank')}>
                    <span className="name">Materials</span>
                    <div className="icon"></div>
                </div>
            </div>
            <div className="add-line">
                <button onClick={() =>  props.setPage(4)}></button>
            </div>
        </div>
    ]
}

export default Materials;