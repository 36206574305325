import { useState } from "react";
import { register, confirmAcc } from '../Disp/endpoint';
function RegForm(props) {
    const [email, setEmail] = useState("");
    const [username, setUserName] = useState("");
    const [date, setDate] = useState("");
    const [pass, setPass] = useState("");
    const [repass, setRePass] = useState("");

    const [confirm, setConfirm] = useState(false);
    const [code, setCode] = useState("");
    const [error, setError] = useState("");

    function Register() {
        if (!email || !username || !date || !pass) {
            setError('All fields are required');
            return;
        }
        register(email, username, date, pass).then(result => {
            switch (result) {
                case "ok":
                    setError('');
                    setConfirm(true);
                    break;
                case "exist":
                    setError('This email is already in use');
                    break;
                case "mailerror":
                    setError('This email is not correct');
                    break;
            }
        });
    }

    function goToSign() {
        props.goTo(2);
    }

    function back() {
        setError('');
        setConfirm(false);
    }

    function _confirm() {
        if (!code) {
            setError('Write code please.');
            return;
        }
        confirmAcc(email, code).then(result => {
            switch (result) {
                case "ok":
                    setError('');
                    goToSign();
                    break;
                case "error":
                    setError('Invalid code');
                    break;
            }
        });
    }

    return (
        [confirm == false && <div className="reg">
            <div className="form">
                <h2>REGISTER</h2>
                <p className="error">{error}</p>
                <div className="input">
                    <label>E-mail</label>
                    <input type="text" onChange={(e) => setEmail(e.target.value)} />
                    <span>You can use email or phone</span>
                </div>
                <div className="input">
                    <label>Username</label>
                    <input type="text" onChange={(e) => setUserName(e.target.value)} />
                    <span>Only letters and numbers are allowed</span>
                </div>
                <div className="input">
                    <label>Birthday date</label>
                    <input type="date" onChange={(e) => setDate(e.target.value)} />
                </div>
                <div className="input">
                    <label>Password</label>
                    <input type="password" onChange={(e) => setPass(e.target.value)} />
                    <span>Password must be at least 8 characters and contain numbers, letters and special characters</span>
                </div>               
                <div className="input">
                    <label>Repeat password</label>
                    <input type="password" onChange={(e) => setRePass(e.target.value)} />
                </div>
                <div className="input">
                    <button className="primary" onClick={Register}>Register</button>
                    <p className="terms">By clicking on the button, you agree <br /> to the <a>Terms of Service</a> and <a>Privacy Policy</a></p>
                </div>
            </div>
            <div className="block-login">
                <p>Already have an account?</p>
                <button className="clear" onClick={goToSign}>Log in</button>
            </div>
        </div>,
        confirm && <div className="confirm">
            <div className="form">
                <h2>THANKS FOR SIGN UP!</h2>
                <p className="error">{error}</p>
                <div className="area">
                    <span>We have sent you a confirmation email.<br />Please, check your inbox and enter the code.</span>
                </div>
                <div className="input">
                    <label>Enter the code:</label>
                    <input type="text" style={{ "text-align": "center" }} onChange={(e) => setCode(e.target.value)} />
                </div>
                <div className="buttons-group">
                    <button onClick={back}>Cancel</button>
                    <button onClick={_confirm}>Confirm</button>
                </div>
            </div>
        </div>
        ])
}

export default RegForm;