function getToken() {
    let session = sessionStorage.getItem('token');
    let local = localStorage.getItem('token');
    return session || local;
}

function resetToken() {
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
}

export { getToken, resetToken };