import { useEffect, useState } from "react";
import { profile } from "../../Disp/endpoint";
import { getToken } from "../../Disp/token";

function Settings(props) {
    const [_profile, setProfile] = useState({});
    const [loading, setLoading] = useState(true);

    const getData = async () => {
        let token = getToken();
        let data = await profile(token);
        setLoading(false);
        return data;
    }

    useEffect(async () => {
        (async () => {
            let data = await getData();
            setProfile(data);
        })();
    }, []);

    let photo_url;

    if (_profile && _profile.photoId) {
        photo_url = `https://create.infty.art/files/${_profile.photoId}`
    } else {
        photo_url = 'https://create.infty.art/images/png/blank.png'
    }

    return [
        <div className="back-block"><button className="back-block" onClick={() => props.setPage(3)}></button></div>,
        <h2>SETTINGS</h2>,
        <div className="profile-user"><div className="photo" style={{ 'background-image': `url(${photo_url})` }}></div><span className="profile-name">{_profile.username}</span></div>,
        <div className="absolute">
            <div className="profile-form">
                <div className="profile-buttons">
                    <div className="buttons-name">
                        <h3>GENERAL</h3>
                    </div>
                    <div className="profile-button" onClick={() => props.setPage(10)}>
                        <span className="name">Edit Profile</span>
                        <div className="icon"></div>
                    </div>
                    <div className="profile-button" onClick={() => props.setPage(9)}>
                        <span className="name">Change Password</span>
                        <div className="icon"></div>
                    </div>
                </div>
                <div className="profile-buttons">
                    <div className="buttons-name">
                        <h3>ABOUT & TERMS</h3>
                    </div>
                    <div className="profile-button">
                        <span className="name">About Us</span>
                        <div className="icon"></div>
                    </div>
                    <div className="profile-button">
                        <span className="name">Privacy Policy</span>
                        <div className="icon"></div>
                    </div>
                    <div className="profile-button">
                        <span className="name">Terms of Service</span>
                        <div className="icon"></div>
                    </div>
                    <div className="profile-button" onClick={() => window.open('https://t.me/rvspace_rikibot', '_blank')}>
                        <span className="name">Support</span>
                        <div className="icon"></div>
                    </div>
                </div>
                <div className="add-line">
                    <button onClick={() => props.setPage(4)}></button>
                </div>
            </div>
        </div>
    ]
}

export default Settings;