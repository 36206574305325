import { useState } from "react";
import MainForm from "./Forms/MainForm";
import PrimeForm from "./Forms/PrimeForm";
import RegForm from "./Forms/RegForm";
import ResetForm from "./Forms/ResetForm";
import SignForm from "./Forms/SignForm";

function Point(props) {

    const [form, setForm] = useState(0);

    function goTo(index) {
        setForm(index)
    }
    
    switch (form) {
        case 0:
            return <PrimeForm goTo={goTo}/>
        case 1:
            return <RegForm goTo={goTo}/> 
        case 2:
            return <SignForm goTo={goTo}/>
        case 3:
            return <ResetForm goTo={goTo}/>
        case 4:
            return <MainForm goTo={goTo}/>
    }
}

export default Point;